<template>
    <MainPage :title="$t('lang.msg2')" :nomore="true">
        <div slot="content">
            <!-- <cube-scroll ref="scroll"> -->
            <div class="main-index main-bg2">
                <cube-scroll ref="scroll">
                <!-- <img class="main-banner" src="@/assets/home-banner.jpg"> -->
                    <div style="margin: 0px 0px 40px 0px;padding: 40px 25px;background-color: #222222d6;font-size: 14px;">
                        <form>
                           <div style="margin-top: 15px;">
                                <cube-input v-model="form_reg.username"
                                    :placeholder="$t('lang.msg3')"
                                    type="text"
                                    :clearable="{visible: true, blurHidden: true}" class="reg-field"
                                    autocomplete="false"
                                    >
                                    <i slot="prepend" class="fas fa-user reg-icon"></i>
                                </cube-input>
                                <cube-validator v-model="check0" :model="form_reg.username" :rules="rules0" class="error-msg"></cube-validator>
                            </div>
                            <div style="margin-top: 10px;display: flex;">
                                <cube-input v-model="form_reg.vcode"
                                    :placeholder="$t('lang.msg251')"
                                    type="text"
                                    :clearable="{visible: true, blurHidden: true}" class="reg-field"
                                    autocomplete="false"
                                    >
                                    <i slot="prepend" class="fa-solid fa-image reg-icon"></i>
                                </cube-input>                            
                                <img v-bind:src="imgBase64" @click="refreshCaptcha()"  style="background: #fff;width: 130px;">
                            </div>
<!--                            <div style="margin-top: 10px;">
                                <cube-select
                                  v-model="form_reg.country" :title="$t('lang.msg42')" :cancelTxt="$t('lang.msg43')" :confirmTxt="$t('lang.msg44')" 
                                  :options="country_type" style="width: 100%;">
                                </cube-select>
                            </div> -->
<!--                            <div style="margin-top: 10px;">
                                <cube-input v-model="form_reg.phone"
                                    :placeholder="$t('lang.msg4')"
                                    type="text"
                                    :clearable="{visible: true, blurHidden: true}" class="reg-field"
                                    autocomplete="false"
                                    >
                                    <i slot="prepend" class="fas fa-phone reg-icon"></i>
                                </cube-input>
                                <cube-validator v-model="check4" :model="form_reg.phone" :rules="rules4" class="error-msg"></cube-validator>
                            </div>
                            <div style="margin-top: 10px;">
                                <div style="display: flex;">
                                    <cube-input v-model="form_reg.scode"
                                        :placeholder="$t('lang.msg252')"
                                        type="text"
                                        :clearable="{visible: true, blurHidden: true}" class="reg-field"
                                        autocomplete="false"
                                        >
                                        <i slot="prepend" class="fa-solid fa-shield-halved reg-icon"></i>
                                    </cube-input>
                                    <cube-button  @click="smssend" class="reg-button blue-btn" style="width: 100px;" :disabled="form_reg.vcode == '' || form_reg.phone == '' || smswait > 0" :class="{'disabled-btn':form_reg.vcode == ''|| form_reg.phone == '' || smswait > 0 }">
                                    <span v-if="smswait==0">{{$t('lang.msg253')}}</span>
                                    <span v-else>{{smswait}}s</span>
                                    </cube-button>
                                </div>
                                <cube-validator v-model="check6" :model="form_reg.scode" :rules="rules4" class="error-msg"></cube-validator>
                            </div> -->
                            <div style="margin-top: 10px;">
                                <cube-input v-model="form_reg.pwd"
                                    :placeholder="$t('lang.msg195')"
                                    type="password"
                                    :eye="{open: false,reverse: false}"
                                    class="reg-field"
                                    autocomplete="false"
                                    >
                                    <i slot="prepend" class="fas fa-unlock-alt reg-icon"></i>
                                </cube-input>
                                <cube-validator v-model="check1" :model="form_reg.pwd" :rules="rules1" class="error-msg"></cube-validator>
                            </div>
                            <div style="margin-top: 10px;">
                                <cube-input v-model="form_reg.confirm_pwd"
                                    :placeholder="$t('lang.msg196')"
                                    type="password"
                                    :eye="{open: false,reverse: false}"
                                    class="reg-field"
                                    autocomplete="false"
                                    >
                                    <i slot="prepend" class="fas fa-unlock-alt reg-icon"></i>
                                </cube-input>
                                <cube-validator v-model="check2" :model="form_reg.confirm_pwd" :rules="rules2" class="error-msg"></cube-validator>
                            </div>
                            <div style="margin-top: 10px;">
                                <cube-input v-model="form_reg.code"
                                    :placeholder="$t('lang.msg7')"
                                    type="text"
                                    :clearable="{visible: true, blurHidden: true}"
                                    class="reg-field"
                                    :disabled="checkCode"
                                    >
                                    <i slot="prepend" class="fas fa-qrcode reg-icon"></i>
                                </cube-input>
                                <cube-validator v-model="check5" :model="form_reg.code" :rules="rules5" class="error-msg"></cube-validator>
                            </div>
<!--                            <div style="display: flex;font-size: 12px;">
                                <cube-checkbox v-model="checked" class="reg-checkbox" >
                                {{$t('lang.msg188')}}
                                </cube-checkbox>
                                <div style="line-height: 45px;"  @click="chkdoc" class="doc-color">
                                    《{{$t('lang.msg189')}}》
                                </div>
                            </div> -->
                            <div class="padding">
                                <cube-button  @click="register" class="reg-button blue-btn" :disabled="!check0 || !check1 || !check2 || !check5 || form_reg.code == '' " :class="{'disabled-btn':!check0 || !check1 || !check2 || !check4 || !check6 || form_reg.code == ''  }">{{$t('lang.msg10')}}</cube-button>
                            </div>
                        </form>
                    </div>
                </cube-scroll>
            </div>
            <!-- </cube-scroll> --> 
        </div>    
    </MainPage>
</template>
<script>
import { Locale } from 'cube-ui'
import MainPage from '@/components/nav-bar.vue'
import { validator } from '@/utils/validator.js'
import { mapGetters } from "vuex";
import { apiReg,apiGetCaptcha,apiSmsSend } from "@/api.js";
import { globalMixin } from '@/mixin.js';
export default {
    mixins: [globalMixin,Locale.localeMixin],
    data() {
        return {
            rnd:1,
            smswait:0,
            imgBase64:'',
            checked:false,
            showDetail:0,
            check0:false,
            check1:false,
            check2:false,
            check4:false,
            check5:this.$route.params.code ? true : false,
            check6:false,
            form_reg:{
                username: '',
                phone: '',
                pwd: '',
                confirm_pwd: '',
                code: this.$route.params.code,
                vcode:'',
                scode:'',
                country:'84',
            },
            rules0:{
                type: 'string',
                required: true,
                pattern: validator.username,
            },
            rules4:{
                type: 'string',
                required: true,
                // pattern: validator.phone,
            },
            rules5:{
                type: 'string',
                required: true,
            },
            rules1:{
                type: 'string',
                required: true,
                pattern: validator.password,
                min:6,
            },
            rules2:{
                type: 'string',
                required: true,
                pattern: validator.password,
                min:6,
            }
        }
    },
    methods: {
        countDown() 
        {
            clearInterval(this.cd);
            this.smswait = this.smswait - 1
            if(this.smswait > 0){
                this.cd = setInterval(() => {
                    this.countDown();
                }, 1000);
            }
        },        
        smssend(){
            this.toast('Loading...',null,0,'loading');
            apiSmsSend({
                Mobile:this.form_reg.phone,
                Vcode:this.form_reg.vcode,
                Country : this.form_reg.country
            })
            .then(response => {
                this.$createToast().hide();
                if(!response.data.status)
                {
                    this.error_handler(response.data);
                    this.refreshCaptcha()
                }
                if(response.data.status)
                {
                    this.smswait = 120
                    this.cd = setInterval(() => {
                        this.countDown();
                    }, 1000);
                    this.toast(this.$t('lang.msg254'));
                }
                return;
            }).catch(error => {
                console.log(error);
                return;
            });
        },
		refreshCaptcha(){
			//this.rnd = Math.random();
            apiGetCaptcha({
            })
            .then(response => {
                if(!response.data.status)
                {
                    this.error_handler(response.data);
                }
                if(response.data.status)
                {
                    this.imgBase64 = response.data.data
                }
                return;
            }).catch(error => {
                console.log(error);
                return;
            });
		},        
        closeClick(){
            this.showDetail=0;
        },        
        chkdoc(){
            // if(!validator.isUsername(this.form_reg.username)){
            //     this.toast(this.$t('lang.err2'));
            //     return;
            // }
            // // if(!validator.isPassword(this.form_reg.pwd)){
            // //     this.toast('密碼格式不正確,請輸入6-12位數字及字母組合');
            // //     return;
            // // };
            // if(this.form_reg.pwd != this.form_reg.confirm_pwd){
            //     this.toast(this.$t('lang.err1'));
            //     return;
            // }
            this.showDetail=1;
        },
        // closeClick(){
        //     this.showDetail=0;
        // },
        back()
        {
          this.$router.back()
        },
        register()
        {
            if(!validator.isUsername(this.form_reg.username)){
                this.toast(this.$t('lang.err2'));
                return;
            }
            // if(!validator.isPassword(this.form_reg.pwd)){
            //     this.toast('密碼格式不正確,請輸入6-12位數字及字母組合');
            //     return;
            // };
            if(this.form_reg.pwd != this.form_reg.confirm_pwd){
                this.toast(this.$t('lang.err1'));
                return;
            }
            this.toast('Loading...',null,0,'loading');
            apiReg({
                Phone:this.form_reg.phone,
                Code:this.form_reg.code,
                Username:this.form_reg.username,
                Password:this.form_reg.pwd,
                //RegIP : localStorage.getItem('Ip'),
                RegIP : this.config.MyIP,
                Scode :this.form_reg.scode,
                Vcode:this.form_reg.vcode,
                //Country : this.form_reg.country
            })
            .then(response => {
                this.$createToast().hide();
                if(!response.data.status)
                {
                    this.error_handler(response.data);
                    this.refreshCaptcha()
                }
                if(response.data.status)
                {
                    this.showDetail=0;
                    this.toast(
                        this.$t('lang.msg1'),
                        {
                            func: () => {
                                this.$store.dispatch('setUserInfo',response.data.data);
                                this.$router.push("/game");
                            }
                        });
                    // setTimeout(()=> {
                    //     this.$store.dispatch('getCoin');
                    // }, 15000);
                }
                return;
            }).catch(error => {
                console.log(error);
                return;
            });
        }
    },
    computed: {
		...mapGetters({
            user: "user",
            config: "config",
        }),
        checkCode()
        {
            if(this.$route.params.code==undefined)
            {
                return false
            }else
            {
                return true
            }
        },
        country_type(){
            var data = [];
            for(var n in this.config.country)
            {
                data.push({
                    value:this.config.country[n],
                    text:this.$t("country."+n)+" +"+this.config.country[n]
                });
            }
            return data;
        }        
    },
    components: {
        MainPage
    },
    created() {
        this.refreshCaptcha() 
    },
    destroyed: function()
	{
        clearInterval(this.cd);
    }       
}

</script>
<style lang="stylus" rel="stylesheet/stylus" scoped>

</style>
